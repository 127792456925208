import React from 'react';

function ReneImage() {
    return <img src={"/rene_small.webp"} width={500} height={637} alt="Rene Kathofer" style={styles.image}/>;
}

const styles = {
    image: {
        minWidth: "453",
        width: "100%",
        height: "100%",
        marginBottom: -4,
        objectFit: "cover"
    }
}

export default ReneImage
