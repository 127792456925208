import {
    Box,
    Button,
    Chip,
    Divider,
    Link,
    Stack,
    Typography,
    useTheme
} from "@mui/material";
import ReneImage from "../components/ReneImage";
import Grid2 from "@mui/material/Grid2";
import {HashLink} from "react-router-hash-link";

function KnowledgeAreaBlock({title, knowledges = []}) {
    return <Box style={styles.knowledgeAreaBlock}>
        <Typography variant={"h2"}>
            {title}
        </Typography>
        <Box style={styles.knowledgeAreaChips}>
            {knowledges.map((knowledge) => (
                <Chip key={knowledge} label={knowledge} color={"primary"}/>))}
        </Box>
    </Box>
}

function Home() {
    const theme = useTheme()
    return (
        <Stack style={styles.pageContainer}>
            <Grid2 container style={styles.welcomeAreaContainer}>
                <Grid2 size={{xs: 12, md: 6}}>
                    <Box style={styles.welcomeArea}
                         bgcolor={theme.palette.primary.main}>
                        <Box style={styles.welcomeTextArea} color={"white"}>
                            <Typography variant={"h1"}>
                                Deine Vision, unsere
                            </Typography>
                            <Typography variant={"h1"} noWrap={true}>
                                gemeinsame Umsetzung
                            </Typography>
                            <Typography variant={"body1"} style={styles.welcomeText}>
                                Mein Name ist <b>Rene Kathofer</b> und ich bin ein Full-Stack
                                Entwickler aus Oberösterreich. Ich unterstütze
                                dich gerne bei der Umsetzung bestehender Softwareprojekte oder
                                der Entwicklung neuer Web oder Mobile Applikationen.
                                Eine Liste der Technologien, die vorrangig bei mir zum Einsatz kommen findest <HashLink
                                id="technologies-link" to="/#technologies">weiter unten</HashLink>.
                            </Typography>
                            <Typography variant={"body1"} style={styles.welcomeText}>
                                Am besten reden wir einfach kurz miteinander oder du schreibst
                                mir eine E-Mail:
                            </Typography>
                            <Typography variant={"body1"} style={styles.welcomeText}>
                                <Stack> <Button href="tel:+436705531007" variant={"contained"}
                                                color={"secondary"}
                                                style={styles.welcomeTextButton}>+43
                                    670 553 1007</Button><br/>
                                    <Button href="mailto:mail@rene-kathofer.at"
                                            variant={"contained"} color={"secondary"}
                                            style={styles.welcomeTextButton}>mail@rene-kathofer.at</Button></Stack>
                            </Typography>
                        </Box>
                    </Box>
                </Grid2>
                <Grid2 size={{xs: 12, md: 6}}>
                    <Box style={styles.imageContainer}>
                        <ReneImage/>
                    </Box>
                </Grid2>
            </Grid2>

            <Box style={styles.infoBox} bgcolor={theme.palette.secondary.main}>
                <Typography variant={"h1"}>
                    Projekte
                </Typography>
                <Stack gap={2} marginTop={2}>
                    <Box>
                        <Typography variant={"h2"}>
                            B2B Shop Anwendung
                        </Typography>
                        <Typography variant={"body1"}>
                            In der Shop-Anwendung können autorisierte Händler/innen Produkte für den weiteren Verkauf
                            erwerben. SAP
                            Commerce Cloud wurde als Teil des Backends verwendet, um die Shop-Features optimal
                            umzusetzen. Ein
                            besonderes Augenmerk wurde auf die Performance der Anwendung gelegt.
                        </Typography>
                    </Box>
                    <Divider/>
                    <Box>
                        <Typography variant={"h2"}>
                            bassle.app
                        </Typography>
                        <Typography variant={"body1"}>
                            Eine Tauschplattform für mobile Endgeräte (Android und iOS), auf
                            der
                            Benutzer Gegenstände wie Bücher, Kleidung und Videospiele
                            tauschen
                            können. Einfach zu bedienen und mit Fokus auf das Finden
                            potenziell
                            interessanter Gegenstände.
                        </Typography>
                    </Box>
                    <Divider/>
                    <Box>
                        <Typography variant={"h2"}>
                            Customer Relationship Management (Versicherung)
                        </Typography>
                        <Typography variant={"body1"}>
                            Eine Applikation, die zwei externe Systeme verbindet und Daten
                            für
                            Speicherung und Analyse übermittelt.
                        </Typography>
                    </Box>
                    <Divider/>
                    <Box>
                        <Typography variant={"h2"}>
                            Customer Relationship Management (Bank)
                        </Typography>
                        <Typography variant={"body1"}>
                            Entwicklung einer CRM-Lösung für Bankberater zur Betreuung von
                            Privat- und Geschäftskunden. Arbeiten an CI/CD, Microservices,
                            Frontends, Teststrategien und Qualitätskontrolle.
                        </Typography>
                    </Box>
                    <Divider/>
                    <Box>
                        <Typography variant={"h2"}>
                            Applikation zur Erhebung von Statistikdaten
                        </Typography>
                        <Typography variant={"body1"}>
                            Migration einer Legacy-Applikation in ein modernes System zur
                            Erhebung relevanter Daten für die Kundengruppe. Erweiterung um
                            neue
                            Features.
                        </Typography>
                    </Box>
                </Stack>
            </Box>

            <Box style={styles.infoBox} bgcolor={theme.palette.secondary.main}>
                <Box style={styles.knowledgeAreaHeader}>
                    <Typography id={"technologies"} variant={"h1"}>
                        Technologien
                    </Typography>
                </Box>
                <Stack gap={2} marginTop={2}>
                    <KnowledgeAreaBlock title={"Backend"}
                                        knowledges={["Java", "Kotlin", "Spring Boot",
                                            "Jakarta EE", "WildFly"]}/>
                    <Divider/>
                    <KnowledgeAreaBlock title={"DevOps"}
                                        knowledges={["GitLab", "GitLab CI", "Jenkins",
                                            "Docker", "ActiveMQ",
                                            "AWS", "Microsoft Azure"]}/>
                    <Divider/>
                    <KnowledgeAreaBlock title={"Web"}
                                        knowledges={["React", "Jakarta EE",
                                            "Javascript", "Typescript"]}/>
                    <Divider/>
                    <KnowledgeAreaBlock title={"Mobile"} knowledges={["React Native"]}/>
                    <Divider/>
                    <KnowledgeAreaBlock title={"Agile Methoden"}
                                        knowledges={["Scrum Framework",
                                            "CSM (2017-2019)"]}/>
                    <Divider/>
                    <KnowledgeAreaBlock title={"Sonstiges"}
                                        knowledges={["Git", "Clean Code",
                                            "TDD", "SQL"]}/>
                </Stack>
            </Box>
        </Stack>
    )
}

const styles = {
    pageContainer: {
        gap: 20
    },
    welcomeAreaContainer: {
        overflow: "hidden",
        borderRadius: 30,
    },
    welcomeArea: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        height: "100%"
    },
    welcomeTextArea: {
        margin: 40
    },
    welcomeText: {
        marginTop: 30
    },
    welcomeTextButton: {
        textTransform: 'none',
    },
    infoBox: {
        borderRadius: 30,
        padding: 40,
    },
    imageContainer: {
        height: "100%"
    },
    knowledgeAreaHeader: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
        overflow: "hidden"
    },
    knowledgeAreaBlock: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        overflow: "hidden"
    },
    knowledgeAreaChips: {
        display: "flex",
        justifyContent: "flex-end",
        flexWrap: "wrap",
        gap: 5
    },
};

export default Home;
