import {
  Box,
  Button,
  Container,
  createTheme, Link,
  Stack,
  ThemeProvider
} from "@mui/material";
import Logo from "./components/Logo";
import {HashRouter, Link as RouterLink, Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import Impressum from "./pages/Impressum";
import Datenschutz from "./pages/Datenschutz";

import LinkedInIcon from '@mui/icons-material/LinkedIn';
import {GitHub} from "@mui/icons-material";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(9, 0, 44)"
    },
    secondary: {
      main: "rgb(205,203,215)"
    },
  },
  typography: {
    h1: {
      fontSize: 30,
      fontWeight:"bold"
    },
    h2: {
      fontSize: 18,
      fontWeight:"bold"
    },
  },
});

function App() {
  return (
      <ThemeProvider theme={theme}>
        <HashRouter>
          <Container style={styles.container}>
            <Box>
              <Stack direction={"row"} style={styles.content}>
                <Stack style={styles.header}>
                  <RouterLink to={"/"}>
                    <Logo/>
                  </RouterLink>
                </Stack>
                <Stack direction={"row"} style={styles.menu}>
                  <Button component={RouterLink} variant={"outlined"}
                          to={"/"}>Home</Button>
                  <Button component={RouterLink} variant={"outlined"}
                          to={"/datenschutz"}>Datenschutz</Button>
                  <Button component={RouterLink} variant={"outlined"}
                          to={"/impressum"}>Impressum</Button>

                  <Link title={"LinkedIn Link"} area-label={"LinkedIn Link"}
                        href="https://www.linkedin.com/in/rene-kathofer-013801243"
                        target="_blank">
                    <LinkedInIcon sx={{fontSize: 45}}/>
                  </Link>

                  <Link title={"Github Link"} area-label={"Github Link"}
                        href="https://github.com/rKatex"
                        target="_blank">
                    <GitHub sx={{fontSize: 38}}/>
                  </Link>
                </Stack>
              </Stack>
              <Routes>
                <Route path="/">
                  <Route index element={<Home/>}/>
                  <Route path={"datenschutz"} element={<Datenschutz/>}/>
                  <Route path={"impressum"} element={<Impressum/>}/>
                </Route>
              </Routes>
            </Box>
          </Container>
        </HashRouter>
      </ThemeProvider>
  )
}

const styles = {
  container: {
    marginBottom: 20
  },
  content: {
    margin: 20,
    justifyContent: "space-between"
  },
  header: {
    justifyContent: "center",
  },
  menu: {
    justifyContent: "flex-end",
    alignItems: "center",
    flexWrap: "wrap",
    gap: 10,
  },
};

export default App;
