import {Box, useTheme} from "@mui/material";

function Datenschutz() {
    const theme = useTheme()

    return (
        <Box style={styles.datenschutzArea} bgcolor={theme.palette.secondary.main}>
            <h1 className="adsimple-122387473">Datenschutzerklärung</h1>
            <h2 id="einleitung-ueberblick" className="adsimple-122387473">Einleitung und Überblick</h2>
            <p>Wir haben diese Datenschutzerklärung (Fassung 04.01.2023-122387473) verfasst, um Ihnen gemäß der Vorgaben
                der <a className="adsimple-122387473"
                       href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=122387473#d1e2269-1-1"
                       target="_blank" rel="noopener noreferrer">Datenschutz-Grundverordnung (EU) 2016/679</a> und anwendbaren
                nationalen Gesetzen zu erklären, welche personenbezogenen Daten (kurz Daten) wir als
                Verantwortliche &#8211; und die von uns beauftragten Auftragsverarbeiter (z. B.
                Provider) &#8211; verarbeiten, zukünftig verarbeiten werden und welche rechtmäßigen Möglichkeiten Sie
                haben. Die verwendeten Begriffe sind geschlechtsneutral zu verstehen.<br/>
                <strong className="adsimple-122387473">Kurz gesagt:</strong> Wir informieren Sie umfassend über Daten,
                die wir über Sie verarbeiten.</p>
            <p>Datenschutzerklärungen klingen für gewöhnlich sehr technisch und verwenden juristische Fachbegriffe.
                Diese Datenschutzerklärung soll Ihnen hingegen die wichtigsten Dinge so einfach und transparent wie
                möglich beschreiben. Soweit es der Transparenz förderlich ist, werden technische <strong
                    className="adsimple-122387473">Begriffe leserfreundlich erklärt</strong>, Links zu weiterführenden
                Informationen geboten und <strong className="adsimple-122387473">Grafiken</strong> zum Einsatz gebracht.
                Wir informieren damit in klarer und einfacher Sprache, dass wir im Rahmen unserer Geschäftstätigkeiten
                nur dann personenbezogene Daten verarbeiten, wenn eine entsprechende gesetzliche Grundlage gegeben ist.
                Das ist sicher nicht möglich, wenn man möglichst knappe, unklare und juristisch-technische Erklärungen
                abgibt, so wie sie im Internet oft Standard sind, wenn es um Datenschutz geht. Ich hoffe, Sie finden die
                folgenden Erläuterungen interessant und informativ und vielleicht ist die eine oder andere Information
                dabei, die Sie noch nicht kannten.<br/>
                Wenn trotzdem Fragen bleiben, möchten wir Sie bitten, sich an die unten bzw. im Impressum genannte
                verantwortliche Stelle zu wenden, den vorhandenen Links zu folgen und sich weitere Informationen auf
                Drittseiten anzusehen. Unsere Kontaktdaten finden Sie selbstverständlich auch im Impressum.</p>
            <h2 id="anwendungsbereich" className="adsimple-122387473">Anwendungsbereich</h2>
            <p>Diese Datenschutzerklärung gilt für alle von uns im Unternehmen verarbeiteten personenbezogenen Daten und
                für alle personenbezogenen Daten, die von uns beauftragte Firmen (Auftragsverarbeiter) verarbeiten. Mit
                personenbezogenen Daten meinen wir Informationen im Sinne des Art. 4 Nr. 1 DSGVO wie zum Beispiel Name,
                E-Mail-Adresse und postalische Anschrift einer Person. Die Verarbeitung personenbezogener Daten sorgt
                dafür, dass wir unsere Dienstleistungen und Produkte anbieten und abrechnen können, sei es online oder
                offline. Der Anwendungsbereich dieser Datenschutzerklärung umfasst:</p>
            <ul className="adsimple-122387473">
                <li className="adsimple-122387473">alle Onlineauftritte (Websites, Onlineshops), die wir betreiben</li>
                <li className="adsimple-122387473">Social Media Auftritte und E-Mail-Kommunikation</li>
                <li className="adsimple-122387473">mobile Apps für Smartphones und andere Geräte</li>
            </ul>
            <p>
                <strong className="adsimple-122387473">Kurz gesagt:</strong> Die Datenschutzerklärung gilt für alle
                Bereiche, in denen personenbezogene Daten im Unternehmen über die genannten Kanäle strukturiert
                verarbeitet werden. Sollten wir außerhalb dieser Kanäle mit Ihnen in Rechtsbeziehungen eintreten, werden
                wir Sie gegebenenfalls gesondert informieren.</p>
            <h2 id="rechtsgrundlagen" className="adsimple-122387473">Rechtsgrundlagen</h2>
            <p>In der folgenden Datenschutzerklärung geben wir Ihnen transparente Informationen zu den rechtlichen
                Grundsätzen und Vorschriften, also den Rechtsgrundlagen der Datenschutz-Grundverordnung, die uns
                ermöglichen, personenbezogene Daten zu verarbeiten.<br/>
                Was das EU-Recht betrifft, beziehen wir uns auf die VERORDNUNG (EU) 2016/679 DES EUROPÄISCHEN PARLAMENTS
                UND DES RATES vom 27. April 2016. Diese Datenschutz-Grundverordnung der EU können Sie selbstverständlich
                online auf EUR-Lex, dem Zugang zum EU-Recht, unter <a className="adsimple-122387473"
                                                                      href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679">https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679</a> nachlesen.
            </p>
            <p>Wir verarbeiten Ihre Daten nur, wenn mindestens eine der folgenden Bedingungen zutrifft:</p>
            <ol>
                <li className="adsimple-122387473">
                    <strong className="adsimple-122387473">Einwilligung</strong> (Artikel 6 Absatz 1 lit. a DSGVO): Sie
                    haben uns Ihre Einwilligung gegeben, Daten zu einem bestimmten Zweck zu verarbeiten. Ein Beispiel
                    wäre die Speicherung Ihrer eingegebenen Daten eines Kontaktformulars.
                </li>
                <li className="adsimple-122387473">
                    <strong className="adsimple-122387473">Vertrag</strong> (Artikel 6 Absatz 1 lit. b DSGVO): Um einen
                    Vertrag oder vorvertragliche Verpflichtungen mit Ihnen zu erfüllen, verarbeiten wir Ihre Daten. Wenn
                    wir zum Beispiel einen Kaufvertrag mit Ihnen abschließen, benötigen wir vorab personenbezogene
                    Informationen.
                </li>
                <li className="adsimple-122387473">
                    <strong className="adsimple-122387473">Rechtliche Verpflichtung</strong> (Artikel 6 Absatz 1 lit. c
                    DSGVO): Wenn wir einer rechtlichen Verpflichtung unterliegen, verarbeiten wir Ihre Daten. Zum
                    Beispiel sind wir gesetzlich verpflichtet Rechnungen für die Buchhaltung aufzuheben. Diese enthalten
                    in der Regel personenbezogene Daten.
                </li>
                <li className="adsimple-122387473">
                    <strong className="adsimple-122387473">Berechtigte Interessen</strong> (Artikel 6 Absatz 1 lit. f
                    DSGVO): Im Falle berechtigter Interessen, die Ihre Grundrechte nicht einschränken, behalten wir uns
                    die Verarbeitung personenbezogener Daten vor. Wir müssen zum Beispiel gewisse Daten verarbeiten, um
                    unsere Website sicher und wirtschaftlich effizient betreiben zu können. Diese Verarbeitung ist somit
                    ein berechtigtes Interesse.
                </li>
            </ol>
            <p>Weitere Bedingungen wie die Wahrnehmung von Aufnahmen im öffentlichen Interesse und Ausübung öffentlicher
                Gewalt sowie dem Schutz lebenswichtiger Interessen treten bei uns in der Regel nicht auf. Soweit eine
                solche Rechtsgrundlage doch einschlägig sein sollte, wird diese an der entsprechenden Stelle
                ausgewiesen.</p>
            <p>Zusätzlich zu der EU-Verordnung gelten auch noch nationale Gesetze:</p>
            <ul className="adsimple-122387473">
                <li className="adsimple-122387473">In <strong className="adsimple-122387473">Österreich</strong> ist
                    dies das Bundesgesetz zum Schutz natürlicher Personen bei der Verarbeitung personenbezogener Daten (<strong
                        className="adsimple-122387473">Datenschutzgesetz</strong>), kurz <strong
                        className="adsimple-122387473">DSG</strong>.
                </li>
                <li className="adsimple-122387473">In <strong className="adsimple-122387473">Deutschland</strong> gilt
                    das <strong className="adsimple-122387473">Bundesdatenschutzgesetz</strong>, kurz<strong
                        className="adsimple-122387473"> BDSG</strong>.
                </li>
            </ul>
            <p>Sofern weitere regionale oder nationale Gesetze zur Anwendung kommen, informieren wir Sie in den
                folgenden Abschnitten darüber.</p>
            <h2 id="kontaktdaten-verantwortliche" className="adsimple-122387473">Kontaktdaten des Verantwortlichen</h2>
            <p>Sollten Sie Fragen zum Datenschutz oder zur Verarbeitung personenbezogener Daten haben, finden Sie
                nachfolgend die Kontaktdaten der verantwortlichen Person bzw. Stelle:<br/>
                <span className="adsimple-122387473" style={{fontWeight: 400}}>
</span>
            </p>
            <h2 id="speicherdauer" className="adsimple-122387473">Speicherdauer</h2>
            <p>Dass wir personenbezogene Daten nur so lange speichern, wie es für die Bereitstellung unserer
                Dienstleistungen und Produkte unbedingt notwendig ist, gilt als generelles Kriterium bei uns. Das
                bedeutet, dass wir personenbezogene Daten löschen, sobald der Grund für die Datenverarbeitung nicht mehr
                vorhanden ist. In einigen Fällen sind wir gesetzlich dazu verpflichtet, bestimmte Daten auch nach
                Wegfall des ursprüngliches Zwecks zu speichern, zum Beispiel zu Zwecken der Buchführung.</p>
            <p>Sollten Sie die Löschung Ihrer Daten wünschen oder die Einwilligung zur Datenverarbeitung widerrufen,
                werden die Daten so rasch wie möglich und soweit keine Pflicht zur Speicherung besteht, gelöscht.</p>
            <p>Über die konkrete Dauer der jeweiligen Datenverarbeitung informieren wir Sie weiter unten, sofern wir
                weitere Informationen dazu haben.</p>
            <h2 id="rechte-dsgvo" className="adsimple-122387473">Rechte laut Datenschutz-Grundverordnung</h2>
            <p>Gemäß Artikel 13, 14 DSGVO informieren wir Sie über die folgenden Rechte, die Ihnen zustehen, damit es zu
                einer fairen und transparenten Verarbeitung von Daten kommt:</p>
            <ul className="adsimple-122387473">
                <li className="adsimple-122387473">Sie haben laut Artikel 15 DSGVO ein Auskunftsrecht darüber, ob wir
                    Daten von Ihnen verarbeiten. Sollte das zutreffen, haben Sie Recht darauf eine Kopie der Daten zu
                    erhalten und die folgenden Informationen zu erfahren:
                    <ul className="adsimple-122387473">
                        <li className="adsimple-122387473">zu welchem Zweck wir die Verarbeitung durchführen;</li>
                        <li className="adsimple-122387473">die Kategorien, also die Arten von Daten, die verarbeitet
                            werden;
                        </li>
                        <li className="adsimple-122387473">wer diese Daten erhält und wenn die Daten an Drittländer
                            übermittelt werden, wie die Sicherheit garantiert werden kann;
                        </li>
                        <li className="adsimple-122387473">wie lange die Daten gespeichert werden;</li>
                        <li className="adsimple-122387473">das Bestehen des Rechts auf Berichtigung, Löschung oder
                            Einschränkung der Verarbeitung und dem Widerspruchsrecht gegen die Verarbeitung;
                        </li>
                        <li className="adsimple-122387473">dass Sie sich bei einer Aufsichtsbehörde beschweren können
                            (Links zu diesen Behörden finden Sie weiter unten);
                        </li>
                        <li className="adsimple-122387473">die Herkunft der Daten, wenn wir sie nicht bei Ihnen erhoben
                            haben;
                        </li>
                        <li className="adsimple-122387473">ob Profiling durchgeführt wird, ob also Daten automatisch
                            ausgewertet werden, um zu einem persönlichen Profil von Ihnen zu gelangen.
                        </li>
                    </ul>
                </li>
                <li className="adsimple-122387473">Sie haben laut Artikel 16 DSGVO ein Recht auf Berichtigung der Daten,
                    was bedeutet, dass wir Daten richtig stellen müssen, falls Sie Fehler finden.
                </li>
                <li className="adsimple-122387473">Sie haben laut Artikel 17 DSGVO das Recht auf Löschung („Recht auf
                    Vergessenwerden“), was konkret bedeutet, dass Sie die Löschung Ihrer Daten verlangen dürfen.
                </li>
                <li className="adsimple-122387473">Sie haben laut Artikel 18 DSGVO das Recht auf Einschränkung der
                    Verarbeitung, was bedeutet, dass wir die Daten nur mehr speichern dürfen aber nicht weiter
                    verwenden.
                </li>
                <li className="adsimple-122387473">Sie haben laut Artikel 20 DSGVO das Recht auf Datenübertragbarkeit,
                    was bedeutet, dass wir Ihnen auf Anfrage Ihre Daten in einem gängigen Format zur Verfügung stellen.
                </li>
                <li className="adsimple-122387473">Sie haben laut Artikel 21 DSGVO ein Widerspruchsrecht, welches nach
                    Durchsetzung eine Änderung der Verarbeitung mit sich bringt.
                    <ul className="adsimple-122387473">
                        <li className="adsimple-122387473">Wenn die Verarbeitung Ihrer Daten auf Artikel 6 Abs. 1 lit. e
                            (öffentliches Interesse, Ausübung öffentlicher Gewalt) oder Artikel 6 Abs. 1 lit. f
                            (berechtigtes Interesse) basiert, können Sie gegen die Verarbeitung Widerspruch einlegen.
                            Wir prüfen danach so rasch wie möglich, ob wir diesem Widerspruch rechtlich nachkommen
                            können.
                        </li>
                        <li className="adsimple-122387473">Werden Daten verwendet, um Direktwerbung zu betreiben, können
                            Sie jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten
                            danach nicht mehr für Direktmarketing verwenden.
                        </li>
                        <li className="adsimple-122387473">Werden Daten verwendet, um Profiling zu betreiben, können Sie
                            jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach
                            nicht mehr für Profiling verwenden.
                        </li>
                    </ul>
                </li>
                <li className="adsimple-122387473">Sie haben laut Artikel 22 DSGVO unter Umständen das Recht, nicht
                    einer ausschließlich auf einer automatisierten Verarbeitung (zum Beispiel Profiling) beruhenden
                    Entscheidung unterworfen zu werden.
                </li>
                <li className="adsimple-122387473">Sie haben laut Artikel 77 DSGVO das Recht auf Beschwerde. Das heißt,
                    Sie können sich jederzeit bei der Datenschutzbehörde beschweren, wenn Sie der Meinung sind, dass die
                    Datenverarbeitung von personenbezogenen Daten gegen die DSGVO verstößt.
                </li>
            </ul>
            <p>
                <strong className="adsimple-122387473">Kurz gesagt:</strong> Sie haben Rechte &#8211; zögern Sie nicht,
                die oben gelistete verantwortliche Stelle bei uns zu kontaktieren!</p>
            <p>Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre
                datenschutzrechtlichen Ansprüche in sonst einer Weise verletzt worden sind, können Sie sich bei der
                Aufsichtsbehörde beschweren. Diese ist für Österreich die Datenschutzbehörde, deren Website Sie unter <a
                    className="adsimple-122387473" href="https://www.dsb.gv.at/?tid=122387473" target="_blank"
                    rel="noopener noreferrer">https://www.dsb.gv.at/</a> finden. In Deutschland gibt es für jedes Bundesland einen
                Datenschutzbeauftragten. Für nähere Informationen können Sie sich an die <a
                    className="adsimple-122387473" href="https://www.bfdi.bund.de/DE/Home/home_node.html"
                    target="_blank" rel="noopener noreferrer">Bundesbeauftragte für den Datenschutz und die Informationsfreiheit
                    (BfDI)</a> wenden. Für unser Unternehmen ist die folgende lokale Datenschutzbehörde zuständig:</p>
            <h2 id="oesterreich-datenschutzbehoerde" className="adsimple-122387473">Österreich Datenschutzbehörde</h2>
            <p>
                <strong className="adsimple-122387473">Leiterin: </strong>Mag. Dr. Andrea Jelinek<strong
                className="adsimple-122387473">
                <br/>
                Adresse: </strong>Barichgasse 40-42, 1030 Wien<strong className="adsimple-122387473">
                <br/>
                Telefonnr.: </strong>+43 1 52 152-0<strong className="adsimple-122387473">
                <br/>
                E-Mail-Adresse: </strong>
                <a className="adsimple-122387473" href="mailto:dsb@dsb.gv.at" target="_blank"
                   rel="noopener noreferrer">dsb@dsb.gv.at</a>
                <strong className="adsimple-122387473">
                    <br/>
                    Website: </strong>
                <a className="adsimple-122387473" href="https://www.dsb.gv.at/" target="_blank"
                   rel="noopener noreferrer">https://www.dsb.gv.at/</a>
            </p>
            <h2 id="erklaerung-verwendeter-begriffe" className="adsimple-122387473">Erklärung verwendeter Begriffe</h2>
            <p>Wir sind stets bemüht unsere Datenschutzerklärung so klar und verständlich wie möglich zu verfassen.
                Besonders bei technischen und rechtlichen Themen ist das allerdings nicht immer ganz einfach. Es macht
                oft Sinn juristische Begriffe (wie z. B. personenbezogene Daten) oder bestimmte technische Ausdrücke
                (wie z. B. Cookies, IP-Adresse) zu verwenden. Wir möchte diese aber nicht ohne Erklärung verwenden.
                Nachfolgend finden Sie nun eine alphabetische Liste von wichtigen verwendeten Begriffen, auf die wir in
                der bisherigen Datenschutzerklärung vielleicht noch nicht ausreichend eingegangen sind. Falls diese
                Begriffe der DSGVO entnommen wurden und es sich um Begriffsbestimmungen handelt, werden wir hier auch
                die DSGVO-Texte anführen und gegebenenfalls noch eigene Erläuterungen hinzufügen.</p>
            <h2 id="einwilligung" className="adsimple-122387473">Einwilligung</h2>
            <p>
                <strong className="adsimple-122387473">Begriffsbestimmung nach Artikel 4 der DSGVO</strong>
            </p>
            <p>Im Sinne dieser Verordnung bezeichnet der Ausdruck:</p>
            <blockquote>
                <p>
                    <em>
                        <strong className="adsimple-122387473">„Einwilligung“</strong> der betroffenen Person jede
                        freiwillig für den bestimmten Fall, in informierter Weise und unmissverständlich abgegebene
                        Willensbekundung in Form einer Erklärung oder einer sonstigen eindeutigen bestätigenden
                        Handlung, mit der die betroffene Person zu verstehen gibt, dass sie mit der Verarbeitung der sie
                        betreffenden personenbezogenen Daten einverstanden ist;</em>
                </p>
            </blockquote>
            <p>
                <strong className="adsimple-122387473">Erläuterung: </strong>In der Regel erfolgt bei Websites eine
                solche Einwilligung über ein Cookie-Consent-Tool. Sie kennen das bestimmt. Immer wenn Sie erstmals eine
                Website besuchen, werden Sie meist über einen Banner gefragt, ob Sie der Datenverarbeitung zustimmen
                bzw. einwilligen. Meist können Sie auch individuelle Einstellungen treffen und so selbst entscheiden,
                welche Datenverarbeitung Sie erlauben und welche nicht. Wenn Sie nicht einwilligen, dürfen auch keine
                personenbezogene Daten von Ihnen verarbeitet werden. Grundsätzlich kann eine Einwilligung natürlich auch
                schriftlich, also nicht über ein Tool, erfolgen.</p>
            <h2 id="personenbezogene-daten" className="adsimple-122387473">Personenbezogene Daten</h2>
            <p>
                <strong className="adsimple-122387473">Begriffsbestimmung nach Artikel 4 der DSGVO</strong>
            </p>
            <p>Im Sinne dieser Verordnung bezeichnet der Ausdruck:</p>
            <blockquote>
                <p>
                    <strong className="adsimple-122387473">
                        <em>„personenbezogene Daten“</em>
                    </strong>
                    <em> alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person
                        (im Folgenden „betroffene Person“) beziehen; als identifizierbar wird eine natürliche Person
                        angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem
                        Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu einem oder
                        mehreren besonderen Merkmalen, die Ausdruck der physischen, physiologischen, genetischen,
                        psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person
                        sind, identifiziert werden kann;</em>
                </p>
            </blockquote>
            <p>
                <strong className="adsimple-122387473">Erläuterung:</strong> Personenbezogene Daten sind also all jene
                Daten, die Sie als Person identifizieren können. Das sind in der Regel Daten wie etwa:</p>
            <ul className="adsimple-122387473">
                <li className="adsimple-122387473">Name</li>
                <li className="adsimple-122387473">Adresse</li>
                <li className="adsimple-122387473">E-Mail-Adresse</li>
                <li className="adsimple-122387473">Post-Anschrift</li>
                <li className="adsimple-122387473">Telefonnummer</li>
                <li className="adsimple-122387473">Geburtsdatum</li>
                <li className="adsimple-122387473">Kennnummern wie Sozialversicherungsnummer,
                    Steueridentifikationsnummer, Personalausweisnummer oder Matrikelnummer
                </li>
                <li className="adsimple-122387473">Bankdaten wie Kontonummer, Kreditinformationen, Kontostände uvm.</li>
            </ul>
            <p>Laut Europäischem Gerichtshof (EuGH) zählt auch Ihre <strong className="adsimple-122387473">IP-Adresse zu
                den personenbezogenen Daten</strong>. IT-Experten können anhand Ihrer IP-Adresse zumindest den
                ungefähren Standort Ihres Geräts und in weiterer Folge Sie als Anschlussinhabers feststellen. Daher
                benötigt auch das Speichern einer IP-Adresse eine Rechtsgrundlage im Sinne der DSGVO. Es gibt auch noch
                sogenannte <strong className="adsimple-122387473">„besondere Kategorien“</strong> der personenbezogenen
                Daten, die auch besonders schützenswert sind. Dazu zählen:</p>
            <ul className="adsimple-122387473">
                <li className="adsimple-122387473">rassische und ethnische Herkunft</li>
                <li className="adsimple-122387473">politische Meinungen</li>
                <li className="adsimple-122387473">religiöse bzw. weltanschauliche Überzeugungen</li>
                <li className="adsimple-122387473">die Gewerkschaftszugehörigkeit</li>
                <li className="adsimple-122387473">genetische Daten wie beispielsweise Daten, die aus Blut- oder
                    Speichelproben entnommen werden
                </li>
                <li className="adsimple-122387473">biometrische Daten (das sind Informationen zu psychischen,
                    körperlichen oder verhaltenstypischen Merkmalen, die eine Person identifizieren können).<br/>
                    Gesundheitsdaten
                </li>
                <li className="adsimple-122387473">Daten zur sexuellen Orientierung oder zum Sexualleben</li>
            </ul>
            <h2 id="profiling" className="adsimple-122387473">Profiling</h2>
            <p>
                <strong className="adsimple-122387473">Begriffsbestimmung nach Artikel 4 der DSGVO</strong>
            </p>
            <p>Im Sinne dieser Verordnung bezeichnet der Ausdruck:</p>
            <blockquote>
                <p>
                    <em>
                        <strong className="adsimple-122387473">„Profiling“</strong> jede Art der automatisierten
                        Verarbeitung personenbezogener Daten, die darin besteht, dass diese personenbezogenen Daten
                        verwendet werden, um bestimmte persönliche Aspekte, die sich auf eine natürliche Person
                        beziehen, zu bewerten, insbesondere um Aspekte bezüglich Arbeitsleistung, wirtschaftliche Lage,
                        Gesundheit, persönliche Vorlieben, Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder
                        Ortswechsel dieser natürlichen Person zu analysieren oder vorherzusagen;</em>
                </p>
            </blockquote>
            <p>
                <strong className="adsimple-122387473">Erläuterung:</strong> Beim Profiling werden verschiedene
                Informationen über eine Person zusammengetragen, um daraus mehr über diese Person zu erfahren. Im
                Webbereich wird Profiling häufig für Werbezwecke oder auch für Bonitätsprüfungen angewandt. Web- bzw.
                Werbeanalyseprogramme sammeln zum Beispiel Daten über Ihre Verhalten und Ihre Interessen auf einer
                Website. Daraus ergibt sich ein spezielles Userprofil, mit dessen Hilfe Werbung gezielt an eine
                Zielgruppe ausgespielt werden kann.</p>
            <p>&nbsp;</p>
            <p>Alle Texte sind urheberrechtlich geschützt.</p>
            <p style={{marginTop: 15}}>Quelle: Erstellt mit dem <a href="https://www.adsimple.at/datenschutz-generator/"
                                                                   title="Datenschutz Generator von AdSimple für Österreich">Datenschutz
                Generator</a> von AdSimple</p>

        </Box>
    )
}

const styles = {
    datenschutzArea: {
        borderRadius: 50,
        padding: 24,
        paddingLeft: 40,
        overflow: "hidden"
    }
};

export default Datenschutz;
