import {Box, useTheme} from "@mui/material";

function Impressum() {
    const theme = useTheme()

    return (
        <Box style={styles.impressumArea} bgcolor={theme.palette.secondary.main}>
            <h1 className="adsimple-122387473">Impressum</h1>
            <p className="adsimple-122387473">Angaben gemäß Informationspflicht laut §5 E-Commerce Gesetz, §14
                Unternehmensgesetzbuch, §63 Gewerbeordnung und Offenlegungspflicht laut §25 Mediengesetz.</p>
            <p className="adsimple-122387473">Rene Kathofer, MSc.<br/>Herderstraße 77/1, <br/>4060 Leonding, <br/>Österreich</p>
            <p className="adsimple-122387473">
                <strong>Unternehmensgegenstand:</strong> IT Dienstleistungen<br/>
                <strong>UID-Nummer:</strong> ATU78738458<br/>
                <strong>GLN:</strong> 9110032720072<br/>
                <strong>GISA:</strong> 35362012</p>
            <p className="adsimple-122387473">
              <strong>Tel.:</strong> <a href="tel:+436705531007">+43 670 553 1007</a><br/>
                <strong>E-Mail:</strong> <a href="mailto:mail@rene-kathofer.at">mail@rene-kathofer.at</a>
            </p>
            <p className="adsimple-122387473">
                <strong>Mitglied bei:</strong> WKO<br/>
              <strong>Berufsrecht:</strong> Gewerbeordnung: <a href="https://www.ris.bka.gv.at" target="_blank">www.ris.bka.gv.at</a></p>
            <p className="adsimple-122387473">
                <strong>Aufsichtsbehörde/Gewerbebehörde:</strong> Bezirkshauptmannschaft Linz (Stadt)<br/>
                <strong>Berufsbezeichnung:</strong> Dienstleistungen in der automatischen Datenverarbeitung und
                Informationstechnik<br/>
                <strong>Verleihungsstaat:</strong> Österreich</p>
            <h2 id="eu-streitschlichtung" className="adsimple-122387473">EU-Streitschlichtung</h2>
            <p>Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-Verordnung) möchten wir
                Sie über die Online-Streitbeilegungsplattform (OS-Plattform) informieren.<br/>
                Verbraucher haben die Möglichkeit, Beschwerden an die Online Streitbeilegungsplattform der Europäischen
                Kommission unter <a className="adsimple-122387473"
                                    href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=DE"
                                    target="_blank"
                                    rel="follow noopener">https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=DE</a> zu
                richten. Die dafür notwendigen Kontaktdaten finden Sie oberhalb in unserem Impressum.</p>
            <p>Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder verpflichtet sind, an
                Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
            <h2 id="haftung-fuer-inhalte-dieser-webseite" className="adsimple-122387473">Haftung für Inhalte dieser
                Website</h2>
            <p>Wir entwickeln die Inhalte dieser Website ständig weiter und bemühen uns korrekte und aktuelle
                Informationen bereitzustellen. Leider können wir keine Haftung für die Korrektheit aller Inhalte auf
                dieser Website übernehmen, speziell für jene, die seitens Dritter bereitgestellt wurden. Als
                Diensteanbieter sind wir nicht verpflichtet, die von Ihnen übermittelten oder gespeicherten
                Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
                hinweisen.</p>
            <p>Unsere Verpflichtungen zur Entfernung von Informationen oder zur Sperrung der Nutzung von Informationen
                nach den allgemeinen Gesetzen aufgrund von gerichtlichen oder behördlichen Anordnungen bleiben auch im
                Falle unserer Nichtverantwortlichkeit davon unberührt.</p>
            <p>Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen, bitte wir Sie uns umgehend zu
                kontaktieren, damit wir die rechtswidrigen Inhalte entfernen können. Sie finden die Kontaktdaten im
                Impressum.</p>
            <h2 id="haftung-links-webseite" className="adsimple-122387473">Haftung für Links auf dieser Website</h2>
            <p>Unsere Website enthält Links zu anderen Websites für deren Inhalt wir nicht verantwortlich sind. Haftung
                für verlinkte Websites besteht für uns nicht, da wir keine Kenntnis rechtswidriger Tätigkeiten hatten
                und haben, uns solche Rechtswidrigkeiten auch bisher nicht aufgefallen sind und wir Links sofort
                entfernen würden, wenn uns Rechtswidrigkeiten bekannt werden.</p>
            <p>Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitte wir Sie uns zu kontaktieren. Sie
                finden die Kontaktdaten im Impressum.</p>
            <h2 id="urheberrechtshinweis" className="adsimple-122387473">Urheberrechtshinweis</h2>
            <p>Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen dem Urheberrecht. Bitte fragen
                Sie uns bevor Sie die Inhalte dieser Website verbreiten, vervielfältigen oder verwerten wie zum Beispiel
                auf anderen Websites erneut veröffentlichen. Falls notwendig, werden wir die unerlaubte Nutzung von
                Teilen der Inhalte unserer Seite rechtlich verfolgen.</p>
            <p>Sollten Sie auf dieser Webseite Inhalte finden, die das Urheberrecht verletzen, bitten wir Sie uns zu
                kontaktieren.</p>
            <p>Alle Texte sind urheberrechtlich geschützt.</p>
            <p style={{marginTop: 15}}>Quelle: Erstellt mit dem <a href="https://www.adsimple.at/impressum-generator/"
                                                                   title="Impressum Generator von AdSimple für Österreich">Impressum
                Generator</a> von AdSimple</p>
        </Box>
    )
}

const styles = {
    impressumArea: {
        borderRadius: 50,
        padding: 24,
        paddingLeft: 40,
        overflow: "hidden"
    }
};

export default Impressum;
